import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  styled,
  IconButton,
  CircularProgress
} from "@material-ui/core";

import EmailAccountRegistrationController, {Props,configJSON} from "./EmailAccountRegistrationController";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { sleek } from "./assets";
import * as Yup from "yup";
import { Formik } from "formik";
import CarosalModel from "../../../blocks/carouseldisplay/src/components/CarosalModel.web";
import TermsandConditionsModel from "../../termsconditions/src/components/TermsandConditionsModel.web";

// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   // Customizable Area Start
   renderSignupContainer(){
    return(
      <SignupContainer>
          <Box className="leftContainer">
          <CarosalModel data={this.state.carosalData} />
          </Box>
          <Box className="rightContainer">
              <img src={sleek.default} style={{ width: "min(81px, 5.5vw)", height: "min(27px, 1.7vw)", marginLeft: "auto", position: "absolute", top: "26px", right: "30px"}}/>
              <Box className="outerFormContainer">
                <Box className="formContainer">
                    <p className="formTitle">Create password</p>
                    <Formik
                        initialValues={{
                        passcodeInputField: this.state.password,
                        confirmPasscodeInputField: this.state.reTypePassword,
                        email: this.state.email,
                        }}
                        data-test-id="formik"
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={(values, { setSubmitting, setErrors }) => {  
                          const errors:{ email?:string, passcodeInputField?:string, confirmPasscodeInputField?: string, passcodeDoesNotMatch?: string} = {};
          
                          if (!values.email) {
                            errors.email = configJSON.emailRequired;
                          } 
                          if (!values.passcodeInputField) {
                            errors.passcodeInputField = configJSON.PasscodeRequired;
                          }
                          if (!values.confirmPasscodeInputField){
                            errors.confirmPasscodeInputField = configJSON.confirmPasswordRequired;
                          }
                          
                          if (this.state.password !== this.state.reTypePassword && Object.keys(errors).length === 0){
                            errors.passcodeDoesNotMatch = configJSON.PasscodeMustMatch;
                          }

                          if (!Yup.string().email().isValidSync(values.email) && Object.keys(errors).length === 0) {
                            errors.email = configJSON.invalidEmail;
                          }
          
                          if (Object.keys(errors).length > 0) {
                            setErrors(errors);
                            setSubmitting(false);
                            return ;
                          }    
          
                          this.doEmailSignUp();
                        }}
                    >
                    {({ values, errors, submitCount, setFieldValue, handleSubmit, setErrors }) => (
                    <form className="form" onSubmit={handleSubmit}>
                        <Box>
                            <Box className="inputField" style={{boxShadow: errors && errors.email && submitCount > 0 || this.state.signupFailed ? "0px 0px 3px 0px #C5475B" : ''}}>
                            <p className="inputTitle">Email</p>
                            <InputField
                                name="email"
                                fullWidth={true}
                                value={values.email}
                                disableUnderline={true}
                                data-test-id="emailField"
                                onChange={
                                    (e:{target:{value:string}}) => {
                                        this.setEmail(e.target.value); 
                                        setFieldValue("email", e.target.value); setErrors({...errors, email:""}); this.clearLoginStatus()
                                    }}
                            />
                            </Box>
                            <Box className="inputField" style={{ paddingRight: "50px", marginTop: "min(20px, 1.2vw)", boxShadow: errors && errors.passcodeInputField || this.state.signupFailed || (errors as any).passcodeDoesNotMatch ? "0px 0px 3px 0px #C5475B" : ''}}>
                                <p className="inputTitle">Password</p>
                                <InputField
                                    data-test-id="passwordField"
                                    name="passcodeInputField" fullWidth={true}
                                    inputProps={{
                                      style: {
                                        paddingTop: this.state.enablePasswordField ? '9.5px' : '',
                                        boxSizing:  "border-box",
                                      }
                                    }}
                                    value={ this.state.enablePasswordField ? "*".repeat(this.state.password.length) : this.state.password}
                                    disableUnderline={true}
                                    onChange={(e: any) => {this.setPassword(e.target.value, e.nativeEvent?.data, e.target.selectionStart); setFieldValue("passcodeInputField", e.target.value); this.clearLoginStatus(); setErrors({...errors, passcodeInputField:"", passcodeDoesNotMatch:""} as any)}}
                                />
                                <IconButton
                                    edge="end"
                                    data-test-id="hidePassword"
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    style={{ position: "absolute", top: 0, bottom: 0, right: "14px", cursor: "pointer"}}
                                >
                                    {!this.state.enablePasswordField ? ( <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                                    ) : ( <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/> )}
                                </IconButton>
                            </Box>
                            <Box className="inputField" style={{ paddingRight: "50px", boxShadow: errors && errors.confirmPasscodeInputField && submitCount > 0 || this.state.signupFailed || (errors as any).passcodeDoesNotMatch ? "0px 0px 3px 0px #C5475B" : '', marginTop: "min(20px, 1.2vw)", }}>
                                <p className="inputTitle">Confirm password</p>
                                <InputField
                                    disableUnderline={true}
                                    fullWidth={true} 
                                    inputProps={{
                                      style: {
                                        paddingTop: this.state.enableReTypePasswordField ? '9.5px' : '',
                                        boxSizing:  "border-box",
                                      }
                                    }}
                                    value={ this.state.enableReTypePasswordField ? "*".repeat(this.state.reTypePassword.length) : this.state.reTypePassword}
                                    name="confirmPasscodeInputField" data-test-id="confirmPasswordField"
                                    onChange={(e: any) => {this.setConfirmPassword(e.target.value, e.nativeEvent?.data, e.target.selectionStart); setFieldValue("confirmPasscodeInputField", e.target.value); this.clearLoginStatus(); setErrors({...errors, confirmPasscodeInputField:"", passcodeDoesNotMatch:""} as any)}}
                                />
                                <IconButton
                                    edge="end" data-test-id="hideConfirmPassword"
                                    aria-label="toggle password visibility" onClick={this.handleClickShowConfirmPassword}
                                    style={{ 
                                        top: 0, bottom: 0, 
                                        position: "absolute", right: "14px", 
                                        cursor: "pointer"}}
                                >
                                    {!this.state.enableReTypePasswordField ? (
                                        <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                                    ) : (
                                        <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/>
                                    )}
                                </IconButton>
                            </Box>
                        </Box>
                        <Box style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                          <Box style={{width: "100%", display:"flex", flexDirection:"column-reverse"}}>
                            {( errors && 
                            (errors.email && errors.email.includes("required") || 
                            errors.passcodeInputField && errors.passcodeInputField.includes("required") || 
                            errors.confirmPasscodeInputField && errors.confirmPasscodeInputField.includes("required"))) && submitCount > 0 && 
                            <div className="errorMessage"><ReportProblemOutlinedIcon/> 
                            <p>{errors && 
                                [ errors.email && errors.email.includes("required"),
                                errors.passcodeInputField && errors.passcodeInputField.includes("required"),
                                errors.confirmPasscodeInputField && errors.confirmPasscodeInputField.includes("required")
                                ].filter(Boolean).length >= 2  ? "Missing fields." : "Missing field."}</p></div>}
                            {errors && errors.email && errors.email.includes("Invalid email format") && submitCount > 0 && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Invalid credentials.</p></div>}
                            {errors && ((errors as any).passcodeDoesNotMatch && (errors as any).passcodeDoesNotMatch.includes("PasscodeInputField must match")) && submitCount > 0 && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Passwords do not match.</p></div>}
                            {this.state.signupFailed && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>{this.state.signupFailedMessage}</p></div>}
                            </Box>
                            <Button data-test-id="btnEmailLogIn" type="submit" className="signButton">Continue</Button>
                            <Box style={{height: "min(80px, 10vh)", display:"flex"}}>
                              <p style={{margin: 0,
                                  color: "#BABDD4",
                                  fontWeight: 500, marginTop: "min(20px,2.6vh)",
                                  fontSize: "min(13px, 0.9vw)", lineHeight: "10px",
                                  textAlign: "left", cursor: "pointer",
                                  letterSpacing: "-0.10000000149011612px",fontFamily: "Avenir Next Medium",
                                  }}>By proceeding, you agree to our <span data-test-id={"termsmodelopen"} onClick={this.handleClickTandCFunc} style={{
                                  fontWeight: 500, letterSpacing: "-0.25px",
                                  textAlign: "left", color: "#72749D", 
                                  }}>Terms and Conditions.</span></p>
                            </Box>
                        </Box>
                        <TermsandConditionsModel
                        data-test-id={"termsmodel"} 
                        open={this.state.openTermsDialog}
                        onClose={this.handleCloseFunc}
                        disc={this.state.termsAndConditons}
                        onAccept={this.handleAccept}
                        />
                    </form>
                    )}
                    </Formik>
                </Box>
              </Box>
          </Box>
      </SignupContainer>
    )
   }
  // Customizable Area End
  render() {
    return (
       // Customizable Area Start
     <>
      {
        this.state.isLoading ? 
        <Loader>
          <CircularProgress />
        </Loader>
        :
        this.renderSignupContainer()
      }
     </>
       // Customizable Area End
    );
  }
}
// Customizable Area Start 
const SignupContainer = styled(Box)({
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "space-between",
    flexGrow: 1,
    height: "100%",
    '& .rightContainer':{
      display: "flex",
      padding: "26px",
      flexDirection: "column",
      maxWidth: "538px",
      width: "38%",
      height: "100%",
      boxSizing: "border-box",
      justifyContent: "center"
    },
    '& .leftContainer':{
      height: "100%",
      flexGrow: 1,
      '& div, img':{
        height: "100% !important",
        padding: "0 !important",
        maxWidth: "100% !important"
      }
    },
    '& .outerFormContainer':{
      paddingLeft: "18px",
      paddingRight: "50px",
      width: "100%",
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center"
    },
    '& .formContainer':{
      flexGrow: 1,
      width: "100%",
      display: "flex",
      height: "calc(100% - min(143px, 7vw))",
      borderRadius: "30px",
      flexDirection:"column",
      boxSizing: "border-box",
      padding: "min(30px, 3vh) min(40px, 5vh) min(25px, 2.8vh)",
      backgroundColor: "#ffffff",
      boxShadow: "-2px 10px 19px 0px #D5D5DC, 2px 0px 16px 0px #EEEEF2",
    },
    '& .sleekText':{
      margin: "0",
      textAlign: "end",
    },
    '& .form':{
      flexGrow: 1,
      display: "flex",
      marginBottom: "0",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    '& .formTitle':{
      color: "#4F506D",
      fontSize: "min(36px, 2.5vw)",
      fontWeight: 275,
      textAlign: "left",
      marginTop: "min(31px, 2.8vh)",
      marginBottom: "min(57px, 5vh)",
      lineHeight: "min(49.18px, 3.5vw)",
      fontFamily: "Avenir Next Ultra Light"
    },
    '& .inputField':{
        padding: "6px 16px",
        position: "relative",
        borderRadius: "20px",
        boxShadow: "0px 1px 3px 0px #72749D4F",
      '& .MuiSvgIcon-root':{
        height: "18px",
        width: "18px",
      },
      '& .MuiIconButton-root':{
        marginTop: "auto",
        height: "fit-content",
        marginBottom: "auto"
      }
    },
    '& .inputTitle':{
      margin: 0,
      fontWeight: 300,
      fontSize: "min(12px, 0.9vw)",
      textAlign: "left",
      color: "#ADB1C8",
      lineHeight: "min(22px, 1.3vw)",
      fontFamily: "Avenir Light"
    },
    '& .signButton':{ 
      width: "100%", 
      padding: "min(16px, 2vh)",
      color: "#FFFFFF",
      fontSize: "min(18px, 1.2vw)",
      cursor: "pointer",
      lineHeight: "24px",
      textAlign: "center",
      borderRadius: "12px", 
      fontFamily: "Avenir Next Regular",
      fontWeight: 350,
      textTransform: "none", 
      backgroundColor: "#4F506D",
      '& .MuiButton-root':{
        fontFamily: "inherit"
      }
    },
    '& .rotated-icon': {
      transform: "rotateY(180deg)"
    },
    '& .errorMessage':{
      display: "flex",
      fontSize: "min(12px, 0.9vw)",
      fontWeight: 400,
      color: "#C5475B",
      textAlign: "left",
      marginRight: "auto",
      marginBottom: "min(16px, 1.7vh)",
      fontFamily: "Avenir Next Regular",
      '& p':{
        margin: 0,
        height: "14px",
        marginLeft: "5px",
      },
      '& .MuiSvgIcon-root':{
        width: "16px",
        height: "14px",
      }
    },
    '& .newUser':{
      margin: "0",
      fontSize: "16px",
      fontWeight: 300,
      color: "#595959",
      cursor: "pointer",
      lineHeight: "19px",
      textAlign: "center",
      letterSpacing: "0.10000000149011612px",
      '& span':{
        color: "#000000",
        fontWeight: 500
      }
    }
  })
  
  const InputField = styled(Input)({
      padding: "0",
      margin: "0",
      border: "none",
      width: "100%",
      borderRadius: "20px",
      backgroundColor: "#FFFFFF",
      fontFamily: "Avenir Light",
      fontSize: "min(16px, 1.1vw)",
      color: "#4F506D",
    '& .MuiInputBase-input':{
      padding: 0,
      marginBottom: "1px"
    }
  })

  const Loader = styled('div')({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    '& .MuiCircularProgress-colorPrimary':{
      color: "#4F506D",
    }
  })

// Customizable Area End