import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import countrylist from "./CountryCodeList";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const { baseURL: apiBaseUrl } = require("./../../../framework/src/config");

interface StateType {
  first_name: string;
  last_name: string;
  phone_number: string;
  selectedCountry: string;
  selectedGender: string;
  selectedPhoneCountry: string;
  email: string;
  message: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  activeSteps: number;
  countries: Array<object>;
  gender: Array<Gender>;
  country: Array<Country>;
  industry: Array<Industry>;
  selectedIndustry: string;
  token_local: string | null;
  showErrors: boolean;
  errorsData: string;
  successData: string;
  successDataMsg: string;
  isLoading: boolean;
  isSubmitted: boolean;
  formikData: FormikData;
  // Customizable Area Start
  name: string;
  contractValue:string;
  initialName: string;
  location: string;
  selectedPercentage:string;
  advancedPayment:string;
  retention:string;
  imageFile:File | null; 
  isDirty:boolean;
  projectId:string;
  percentage: Array<{
    label: string;
    value: string;
  }>;
  retentionDropDown:Array<{
    label:string;
    value:string;
  }>;
  advancedPaymentDropdown:Array<{
    label:string;
    value:string;
  }>
  cancelPopupBoolean:boolean;
  openContractCalendar: boolean;
  openCompletionCalendar: boolean;
  contractCalendarValue: string;
  completionCalendarValue: string;
  hoveredImage:number; 
  clickedImage:number[];
  payments: {name:string,value:string}[];
  advancedPct:string;
  retPct:string;
  invalidPayments:number[]
  // Customizable Area End
}

interface FormikData {
  first_name: string;
  last_name: string;
  email: string;
  selectedPhoneCountry: string;
  phone_number: string;
  selectedGender?: string;
  selectedCountry?: string;
  message?: string;
  selectedIndustry?: string;
  countries: Array<object>;
}

interface CustomTypeData {
  contentType: string;
  method: string;
  body: {
    data: CustomTypeBodyData;
  };
  endPoint: string;
}

interface CustomTypeBodyData {
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    gender: string;
    country: string;
    industry: string;
    message: string;
  };
  type: string;
}

interface CustomTypeSubmitvalues {
  first_name: string;
  last_name: string;
  selectedPhoneCountry: string;
  phone_number: string;
  email: string;
  countries: Array<object>;
  selectedGender?: string;
  selectedCountry?: string;
  selectedIndustry?: string;
  message?: string;
}

interface CustomTypevalues {
  first_name: string;
  last_name: string;
  selectedPhoneCountry: string;
  phone_number: string;
  email: string;
  selectedGender: string;
  selectedCountry: string;
  selectedIndustry: string;
  message: string;
}

export interface CountriesList {
  Countries: Array<Countries>;
}

interface Countries {
  name: string;
  dial_code: string;
  code: string;
}

export interface Gender {
  label: string;
  value: string;
}

export interface Industry {
  label: string;
  value: string;
}
export interface Country {
  label: string;
  value: string;
}
interface SSType {
  SSTypeid: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultipageFormsController extends BlockComponent<
  Props,
  StateType,
  SSType
> {
  // Customizable Area Start
  submitTransactionApiCallId: string = "";
  getOrderApiCallId: string = "";
  updateProjectViewApiCallId: string = "";
  showProjectViewApiCallId:string = "";
  getProjectViewApiCallId:string = "";
  getCommercialApiCallId:string = "";
  saveProjectViewApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      first_name: "",
      last_name: "",
      selectedPhoneCountry: "+91",
      phone_number: "",
      email: "",
      message: "",
      selectedCountry: "",
      selectedGender: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      activeSteps: 0,
      countries: countrylist,
      formikData: {
        selectedCountry: "1",
        selectedGender: "1",
        selectedIndustry: "education",
        first_name: "",
        last_name: "",
        email: "",
        selectedPhoneCountry: "",
        message: "",
        phone_number: "",
        countries: countrylist,
      },

      // Add more countries as neede
      gender: [
        { label: "Male", value: "1" },
        { label: "Female", value: "2" },
      ],
      country: [
        { label: "India", value: "1" },
        { label: "America", value: "2" },
        { label: "Canada", value: "3" },
        { label: "England", value: "4" },
      ],
      industry: [
        { label: "Education", value: "education" },
        { label: "Food", value: "food" },
        { label: "Marketing", value: "marketing" },
      ],
      selectedIndustry: "",
      token_local: localStorage.getItem("loginTokenBlock"),
      showErrors: false,
      errorsData: "",
      isLoading: false,
      successData: "",
      isSubmitted: false,
      successDataMsg: "",
      // Customizable Area Start
      name:"",
      contractValue:"",
      initialName:"",
      location: "",
      selectedPercentage:"",
      advancedPayment:"",
      retention:"",
      imageFile:null,
      isDirty:false,
      projectId:"",
      percentage: [
        { label: "12 Month Duration", value: "12_month_duration" },
        { label: "6 Month Duration", value: "6_month_duration" },
        { label: "2 Year Duration", value: "2_year_duration" },
      ],
      retentionDropDown:[
        { value: "applicable_both", label: "Applicable (TOC/DLP)" },
        { value: "applicable_toc", label: "Applicable (TOC)" },
        { value: "applicable_dlp", label: "Applicable (DLP)" },
        { value: "not_applicable", label: "Not Applicable" },
      ],
      advancedPaymentDropdown:[
        { label: "Applicable", value: "applicable" },
        { label: "Not Applicable", value: "not_applicable" },
      ],
      cancelPopupBoolean:false,
      openContractCalendar: false,
      openCompletionCalendar: false,
      contractCalendarValue: "",
      completionCalendarValue: "",
      hoveredImage:0,
      clickedImage:[],
      payments: [{name:"",value:""}],
      advancedPct:"",
      retPct:"",
      invalidPayments:[]
      // Customizable Area End
    };

    // Customizable Area Start
    this.formikRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  getOrderIdFailureCallBack = async (responseJson: string) => {
    alert("@@@@====>");
  };

  // Api response
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        responseJson &&
        responseJson.errors &&
        apiRequestCallId === this.getOrderApiCallId
      ) {
        this.getOrderIdFailureCallBack(responseJson);
      }
      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.getOrderApiCallId
      ) {
        const data = responseJson.data.attributes;
        this.setState({
          successDataMsg: configJSON.successMessage,
          successData: "",
          isSubmitted: true,
          activeSteps: 2,
          first_name: data.first_name,
          last_name: data.first_name,
          email: data.first_name,
          phone_number: data.phone_number,
          selectedGender: data.gender,
          selectedCountry: data.country,
          selectedIndustry: data.industry,
          message: data.message,
          formikData: {
            selectedCountry: "1",
            selectedGender: "1",
            selectedIndustry: "education",
            first_name: "",
            last_name: "",
            email: "",
            selectedPhoneCountry: "",
            message: "",
            phone_number: "",
            countries: countrylist,
          },
        });
      }
      this.handleProfileApiResponse(apiRequestCallId,responseJson);
    }
    // Customizable Area End
  }

  multiPageFormSubmit = async (values: CustomTypevalues) => {
    const selectedCountryLabel = this.state.country.filter((item: Country) => {
      return item.value === values.selectedCountry ? item : null;
    });
    const selectedGenderLabel = this.state.gender.filter((item: Gender) => {
      return item.value === values.selectedGender ? item : null;
    });
    const selectedIndustryLabel = this.state.industry.filter(
      (item: Industry) => {
        return item.value === values.selectedIndustry ? item : null;
      }
    );

    const rawData = {
      data: {
        attributes: {
          first_name: `${values.first_name}`,
          last_name: `${values.last_name}`,
          phone_number: `${values.selectedPhoneCountry} ${values.phone_number}`,
          email: `${values.email}`,
          gender: selectedGenderLabel[0].label,
          country: selectedCountryLabel[0].label,
          industry: selectedIndustryLabel[0].value,
          message: `${values.message}`,
        },
        type: "email_account",
      },
    };

    this.getOrderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      body: rawData,
      endPoint: "/bx_block_multipageforms/user_profiles",
    });
  };
  apiCall = async (data: CustomTypeData) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  stepOnSubmit = (values: CustomTypeSubmitvalues) => {
    this.setState({
      formikData: values,
      activeSteps: this.state.activeSteps + 1,
    });
  };

  handleBack = () => {
    this.setState({ activeSteps: this.state.activeSteps - 1 });
  };

  getSteps() {
    return ["Step 1", "Step 2", "Complete"];
  }

  LoginPage = () => {
    this.props.navigation.navigate("EmailAccountLogin");
  };

  txtInputProps = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start
  formikRef: any;

  async componentDidMount() {
    super.componentDidMount();
    const projectLocalId= await getStorageData("projectId")
    this.setState({projectId:projectLocalId})
    if(this.state.projectId){
      this.showProjectViewData();
    }
  }
  

  componentWillMount =  async()=>{
    const tokeID = await getStorageData("token");
    this.setState({token_local:tokeID})
  }

  async componentDidUpdate(prevProps:any, prevState:any) {
    if (this.formikRef.current) {
      const isDirty = this.formikRef.current.dirty;
      if (isDirty !== prevState.isDirty) {        
        this.setState({ isDirty });
      }
    }
  }

  cancelUpdateHandler = () => {
    this.formikRef?.current.resetForm({
      values: { name: this.state.initialName, location: this.state.location }
    });
  }

  setName = (value: string) => {
    this.setState({ name: value })
  }

  setPhoneNumber = (value: string) => {
    this.setState({ location: value })    
  }
  setDlpDuration = (value: string) => {
    this.setState({ selectedPercentage: value });
  };
  
  handleImageFile=(file:File | null)=>{
    this.setState({imageFile:file})
  }
  cancelPopupHandler = () => {
    this.setState({ cancelPopupBoolean: true })
  }

  cancelPopup=()=>{
    this.setState({cancelPopupBoolean:false})
  }
  confirmCancelPop=()=>{
    this.setState({cancelPopupBoolean:false});
    this.cancelUpdateHandler()
  }

  async handleProfileApiResponse (apiRequestCallId: any,responseJson: any) {
    switch(apiRequestCallId){
      case this.updateProjectViewApiCallId: this.handleSaveProjectViewData(responseJson); break;
      case this.showProjectViewApiCallId: this.handleShowProjectViewData(responseJson); break;
      case this.saveProjectViewApiCallId:this.handleUpdateProjectViewData(responseJson); break;
    }
  }
  handleSaveProjectViewData= (responseJson:any)=>{
    if(responseJson && responseJson.data){
      const setProjectId = responseJson.data.id;
      setStorageData("projectId",setProjectId)
    }
  }

  handleShowProjectViewData=(response:any)=>{
    if(response && response.data){
      this.setState({name:response.data.attributes.name,
        location:response.data.attributes.location,
      })
    }
  }

  handleUpdateProjectViewData = (responseJson:any) =>{
    if(responseJson && responseJson.data){
      this.setState({name:responseJson.data.attributes.name,
        location:responseJson.data.attributes.location,
      })
    }
  }

  checkStepTwo = (values: any, helpers:{ setSubmitting:any, setErrors:any }|any) => {
    let errors:{ advancedPayment?:string,retention?:string,payment?:number[]} = {};
    if (!values.advancedPayment) {
      errors.advancedPayment = "advancedPayment is required";
    }  
    if (!values.retention) {
      errors.retention = "retention is required";
    }  
    const invalidIndexes:number[] = []
    this.state.payments.forEach((payment,index)=>{
      const isInvalid = ((payment.name.trim() !== "" && payment.value == "") ||
      (payment.value !== "" && payment.name.trim() == ""))
      if(isInvalid){
        invalidIndexes.push(index)
      }
    });
    this.setState({
      invalidPayments:invalidIndexes
    })

    if (Object.keys(errors).length > 0 && helpers.setErrors) {
      helpers.setErrors(errors);
      helpers.setSubmitting(false);
      return false;
    }
    return true
  }
  checkStepRequirements = (values:{
    name: string;
    location: string;
    selectedPercentage: string;
  }|any, helpers:{ setSubmitting:any, setErrors:any }|any)=>{
    let errors:{ name?:string,selectedPercentage?:string} = {};
    switch(this.state.activeSteps){
      case 0:
        if (!values?.name) {
          errors.name = "Name is required";
        }  
        if(!values?.selectedPercentage){
          errors.selectedPercentage = "Parcentage required"
        }      

        if (Object.keys(errors).length > 0 && helpers.setErrors) {
          helpers.setErrors(errors);
          helpers.setSubmitting(false);
          return false;
        }
        return true
      case 1:
        return this.checkStepTwo(values,helpers)
    }
    
  }
  handleSaveFinalChanges = (values:any, helpers:{ setSubmitting:any, setErrors:any }) => {  
    if (this.checkStepRequirements(values,helpers)){
      if(this.state.activeSteps==0){
        if (this.state.projectId) {
          this.updateProjectViewData();
        } else {
            this.saveProjectViewData();
        }
      }      
    }
  }

  saveProjectViewData = async () => {
    const header = {
      token: await getStorageData("token")
    };
    let formData = new FormData();
    formData.append('project_overview[name]', this.state.name);
    formData.append("project_overview[completion_date]","");
    formData.append("project_overview[contract_sign_date]","");
    formData.append("project_overview[location]",this.state.location);
    formData.append("project_overview[dlp_duration]", this.state.selectedPercentage);
    if(this.state.imageFile){
      formData.append("project_overview[image]",this.state.imageFile);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProjectViewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveProjectiewApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage as any);
  }

  updateProjectViewData = async () => {
    let formData = new FormData();
    formData.append("project_overview[dlp_duration]", this.state.selectedPercentage);
    if(this.state.imageFile){
      formData.append("project_overview[image]",this.state.imageFile);
    }
    formData.append("project_overview[location]",this.state.location);
    formData.append("project_overview[contract_sign_date]","");
    formData.append("project_overview[completion_date]","");
    formData.append('project_overview[name]', this.state.name);
    const header = {
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveProjectViewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveProjectiewApiEndpoint}/${this.state.projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.examplePutApi
    );
    runEngine.sendMessage(requestMessage.id, requestMessage as any);
  }


  showProjectViewData = async () => {
    const header = {
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showProjectViewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showProjectviewApiEndpoint}/${this.state.projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage as any);
  }

  closeContactCalendar = () => {
    this.setState({ openContractCalendar: false })
  }

  openContactCalendar = () => {
    this.setState({ openContractCalendar: true })
  }

  closeCompletionCalendar = () => {
    this.setState({ openCompletionCalendar: false })
  }

  openCompletionCalendar = () => {
    this.setState({ openCompletionCalendar: true })
  }

  handleContactCalender = (event:Date) => {    
    const date = moment(event).format('MMM DD, YYYY').replace(',', '.');    
    this.setState({ contractCalendarValue:  date,completionCalendarValue:"", openContractCalendar: false})
  }

  handleCompletionCalender = (event:any) => {
    const date = moment(event).format('MMM DD, YYYY').replace(',', '.');    
    this.setState({ completionCalendarValue:  date, openCompletionCalendar:false})
  }

  stepOnSubmited = () => {
    const formikInstance = this.formikRef.current;
    if(this.checkStepRequirements(formikInstance.values,formikInstance)){
      this.setState({
        activeSteps: this.state.activeSteps + 1,
      });
    }
  };

  stepBackOnSubmited = () => {
    this.setState({
      activeSteps: this.state.activeSteps - 1,
    });
  };

  getProjectViewCarosal=() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectViewApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCarosalAPiEndPoint}?query=${"project_overview"}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCommercialCarosal=() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommercialApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCarosalAPiEndPoint}?query=${"commercials"}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMouseEnter = (imageIndex: any) => {
    this.setState({ hoveredImage: imageIndex });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredImage: 0 });
  };
  handleImageClick = (imageIndex: number) => {
    let { clickedImage } = this.state;
    if(clickedImage.includes(imageIndex)){
      clickedImage = clickedImage.filter(index => index!==imageIndex)
    }
    else{
      clickedImage.push(imageIndex)
    }
    this.setState({ clickedImage: clickedImage });
  };



  handlePaymentChange = (index: number, value: string,isName:boolean) => {
    const updatedPayments = [...this.state.payments];
    if(isName){
      updatedPayments[index].name = value;
    }
    else{
      const numericValue = value.replace(/\D/g, '');
      if (numericValue === '' || (Number(numericValue) >= 0 && Number(numericValue) <= 100)) {
        const formattedValue = numericValue ? `${numericValue}%` : '';
        updatedPayments[index].value = formattedValue;
      }
    }
    
    this.setState({ payments: updatedPayments });
  };

  addPaymentField = () => {
    let updatedPayments = [...this.state.payments];
    updatedPayments.push({name:"",value:""})
    this.setState({
      payments:updatedPayments
    })
  };

  
  removePaymentField = (index: number) => {
    if (this.state.payments.length > 1) {
      const updatedPhoneNumbers = [...this.state.payments];
      updatedPhoneNumbers.splice(index, 1); 
      this.setState({ payments: updatedPhoneNumbers });
    }
  };
  
  validateNumberInput = (value: string, setFieldValue: any, fieldName: string) => {
    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    if (/^\d*\.?\d{0,2}$/.test(sanitizedValue)) {
      setFieldValue(fieldName, sanitizedValue);
      this.setState({
        [fieldName as keyof StateType] : sanitizedValue 
      } as unknown as Pick<StateType, keyof StateType>)
    }
  };
 
  handleOnChange = (value: string, fieldName: string, setFieldValue: any ) => {
    setFieldValue(fieldName, value);
    this.setState({
      [fieldName as keyof StateType] : value 
    } as unknown as Pick<StateType, keyof StateType>)
    if(fieldName=="advancedPayment" && value=="not_applicable" && this.state.clickedImage.includes(1)){
      this.handleImageClick(1)
    }
  };

  validatePercentage = (value: string, setFieldValue: any, fieldName: string) => { 
    const numericValue = value.replace(/\D/g, '');
    if (numericValue === '' || (Number(numericValue) >= 0 && Number(numericValue) <= 100)) {
      const formattedValue = numericValue ? `${numericValue}%` : '';
      setFieldValue(fieldName, formattedValue);
      this.setState({
        [fieldName as keyof StateType] : formattedValue 
      } as unknown as Pick<StateType, keyof StateType>)
    }
  };

  isVisibleText= (index:number,isClicked:boolean=false) => {
    if(isClicked)
      return this.state.clickedImage.includes(index) ?"imgSelected":""
    return this.state.hoveredImage==index || this.state.clickedImage.includes(index)
  }
  // Customizable Area End
}
