import { Input, styled } from "@material-ui/core";
import React, { Component } from "react";

interface Row {
  id: number;
  text: string;
  percentage: string;
}

interface State {
  rows: Row[];
  showImage: boolean;
}

export default class AddingInputs extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      rows: [],
      showImage: true,
    };
  }

  addRow = () => {
    this.setState(prevState => ({
      rows: [...prevState.rows, { id: Date.now(), text: "", percentage: "" }],
      showImage: false,
    }));
  };

  handleInputChange = (id: number, field: keyof Row, value: string) => {
    this.setState(prevState => ({
      rows: prevState.rows.map(row =>
        row.id === id ? { ...row, [field]: value } : row
      ),
    }));
  };

  deleteRow = (id: number) => {
    this.setState(prevState => ({
      rows: prevState.rows.filter(row => row.id !== id),
    }));
  };

  render() {
    const { rows, showImage } = this.state;

    return (
      <div>
        {showImage && rows.length === 0 && (
          <img
          src={require("../../assets/addin.png").default}
            alt="Click to Add Row"
            style={{ cursor: "pointer", width: "26px" ,height:"24px"}}
            onClick={this.addRow}
          />
        )}
        {rows.map(row => (
          <div
            key={row.id}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <img onClick={() => this.deleteRow(row.id)} src={require("../../assets/delete.png").default}/>
            <InputField
              type="text"
              fullWidth={true}
              disableUnderline={true}
              value={row.text}
              onChange={e =>
                this.handleInputChange(row.id, "text", e.target.value)
              }
              style={{ marginRight: "10px",marginLeft: "10px", }}
            />
            <InputField
              type="text"
              fullWidth={false}
              disableUnderline={true}
              value={row.percentage}
              onChange={e =>
                this.handleInputChange(row.id, "percentage", e.target.value)
              }
              style={{ width:"64px" }}
            />
          </div>
        ))}

        {rows.length > 0 && (
          <img onClick={this.addRow} style={{ marginTop: "10px" }} src={require("../../assets/addin.png").default}/>          
        )}
      </div>
    );
  }
}

const InputField = styled(Input)({
  fontFamily: "Avenir Light",
  display: "flex",
  width: "256px",
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "16px",
  color: "#696A92",
  padding: "10px 12px",
  borderRadius: "12px",
  boxSizing: "border-box",
  height: "36px",
  boxShadow: "-1.2px 1px 5px 0px #C9CADC, 0.2px 0px 1.8px 0px #E7E8EF",
  '& .MuiInputBase-input':{
      padding: 0
  },
  '&.Mui-focused, &:hover': {
      boxShadow: "-4px 1px 14px 0px #72749D66"
  },
  '& .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline':{
  border:"none"
 }
})
