import { Box, styled } from '@material-ui/core';
import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  selectedDate: string | null;
  onChangeHandler: Function;
  handleclose: Function;
  minDate?: Date; 
  maxDate?: Date;
};

export default class CalendarModal extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(){
    return (
    <>
      <CalendarContainer>
      <Box className='datePickerContainer'>
          <DatePicker
            data-test-id="datePicker"
            selected={this.props.selectedDate ? new Date(this.props.selectedDate) : null}
            onChange={(e) => this.props.onChangeHandler(e)}
            onClickOutside={() => this.props.handleclose()}
            dateFormat="MMM. d, yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={this.props.maxDate || undefined}
            inline
            minDate={this.props.minDate || undefined}
          />
          </Box>
      </CalendarContainer>
    </>
    )
  }
}

const CalendarContainer = styled(Box)({
  position: "absolute",
  zIndex: 30,
  width: "100%",
  paddingBottom: "15px",
  marginTop: "-7px",
  '& .react-datepicker':{
    border: "none",
    boxShadow: "rgba(114, 116, 157, 0.4) -4px 14px 14px 0px",
    borderRadius: "0 0 12px 12px",
    fontFamily: "Avenir Next Regular",
    width: "100%",
    overflow: "hidden"
  },
  '& .react-datepicker__month-container':{
    width: "100%"
  },
  '& .react-datepicker__current-month, .react-datepicker__day-names':{
    fontFamily: "Avenir Next Medium"
  },
  '& .react-datepicker__header':{
    borderColor: "#4F506D",
    backgroundColor: "#FFFFFF"
  },
  '& .react-datepicker__header >div:nth-child(2)':{
    padding: "5px 0"
  },
  '& .react-datepicker__header >div:nth-child(2) select':{
    border: "none",
    boxShadow: "-1px 1px 3px 0px #7678A52E",
    fontFamily: "Avenir Next Medium !important",
    borderRadius: "4px"
  },
  '& .react-datepicker__month, .react-datepicker__day':{
    color: "#4F506D"
  },
  '& .react-datepicker__day--disabled':{
    opacity: 0.4
  },
  '& .react-datepicker__day--selected, .react-datepicker__day--selected:hover':{
    backgroundColor: "#4F506D",
    color: "#FFFFFF"
  },
  '& .react-datepicker__day--keyboard-selected, .react-datepicker__day--keyboard-selected:hover':{
    backgroundColor: "#FFFFFF",
  }
})