export const performanceClicked = require("../assets/performance_clicked.svg").default;
export const sleekImg = require("../assets/sleek.png").default;
export const securityImg = require("../assets/security.png").default;
export const advancePaymentClicked = require("../assets/advance_payment_clicked.svg").default;
export const advancePaymentDisabled = require("../assets/advance_payment_disabled.svg").default;
export const performanceImg = require("../assets/performance.png").default;
export const securityChequeClicked = require("../assets/security_cheque_clicked.svg").default;
export const searchIcon = require("../assets/search_icon.svg").default;
export const project1Img = require("../assets/project1.svg").default;
export const project2Img = require("../assets/project2.svg").default;

