import React from 'react';
import { Box,Button,Dialog,Typography,styled} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

export interface TermsAndConditionsResponse {
  data: TermsAndConditionsData[];
}
export interface TermsAndConditionsData {
  id: string;
  type: string;
  attributes: TermsAndConditionsAttributes;
}
export interface TermsAndConditionsAttributes {
  id: number;
  description: string;
  created_at: string;
}
interface ProviderDialogProps {
  open: boolean;
  disc: TermsAndConditionsResponse;
  onClose: () => void;
  onAccept?:()=> void;
  renderAccept?: boolean;
}
 
const TermsandConditionsModel = ({ open, onClose, disc , onAccept, renderAccept=true}:ProviderDialogProps) => {
  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };
  return (
    <DialogStyling open={open} onClose={onClose} maxWidth="md" fullWidth>
      <TermsConditionsStyles style={{ width: "100%", height: "100%",overflow:"hidden"}}>
        <Box className="headerFiled">
            <Typography className="headerName">Terms and conditions</Typography>
            <CloseIcon style={{height:"24px",width:"24px"}} onClick={handleIconClick} className="closeIcon" />
        </Box>
        <Box className="contentMainWrapper" style={{ paddingBottom: renderAccept ? "0" : "44px", paddingRight:"61px"}}>
          <Box className="contentWrapper">
            <Box className="condTitle">
              {disc && Array.isArray(disc.data) ? (
                disc.data.map((data: TermsAndConditionsData) => (
                  <Box key={data.id} mb={2}>
                    <Typography
                      className="termsContent"
                      dangerouslySetInnerHTML={{ __html: data.attributes.description }}
                    />
                  </Box>
                ))
              ) : (
                <Typography>No terms and conditions available.</Typography>
              )}
            </Box>
          </Box>
        </Box>
        { renderAccept && <Box className='bottombtn'>
          <Button className='acpbtn' onClick={onAccept}>
            Accept
          </Button>
        </Box>
        }
      </TermsConditionsStyles>
    </DialogStyling>
  );
};


export default TermsandConditionsModel

const TermsConditionsStyles = styled(Box)({
  backgroundColor:"#FFFFFF !importent",
  color:"#4F506D",
  fontFamily: "Avenir Next Ultra Light !importent",
  "& .headerFiled":{
    background: '#FFFFFF',
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    padding:"27px 55px 21px",
    position:"sticky",
    top:"0px",
  },
  "& .contentMainWrapper":{
  '& ::-webkit-scrollbar': {
          width: "10px",
          borderRadius: "40px"
        },
      "& ::-webkit-scrollbar-track": {
          backgroundColor: "#F0F2F8",
          borderRadius: "40px"
      },
      "& ::-webkit-scrollbar-thumb":{
          width: "8px",
          backgroundColor: "#B3BFDB",
          borderRadius: "40px",
      },
  },
  "& .contentWrapper":{
    top: '296px',
    gap: '40px',
    opacity: '0px',
    width:'calc(100% - 120px)',
    padding:'0px 60px',
    height:"438px",
    overflowY:"auto",
    '& ::-webkit-scrollbar': {
        width: "10px",
        borderRadius: "40px"
      },
    "& ::-webkit-scrollbar-track": {
        backgroundColor: "#F0F2F8",
        borderRadius: "40px"
    },
    "& ::-webkit-scrollbar-thumb":{
        width: "8px",
        backgroundColor: "#B3BFDB",
        borderRadius: "40px",
    },
  },
  "& .headerName":{
    fontSize: '36px',
    fontWeight: 700,
    lineheight: '49.18px',
    letterSpacing: '-0.015em',
    textAlign: 'left',
    color:"#4F506D",
    fontFamily: "Avenir Next Medium"
  },
  "& .condTitle":{
    fontFamily: "Avenir Next Ultra Light",
    fontWeight: 600,
    lineHeight: '16px',
    textAlign: 'left',
    color:'#D3D3D3',
    paddingBottom: '2px',
    '& ::-webkit-scrollbar': {
          width: "10px",
          borderRadius: "40px"
        },
      "& ::-webkit-scrollbar-track": {
          backgroundColor: "#F0F2F8",
          borderRadius: "40px"
      },
      "& ::-webkit-scrollbar-thumb":{
          width: "8px",
          backgroundColor: "#B3BFDB",
          borderRadius: "40px",
      },
  },
'& .customBtn':{
  minWidth: '116px',
  height: '48px',
  marginTop: '16px',
},
"& .termsContent":{
    fontFamily: "Avenir Regular",
    fontWeight: 500,
    lineHeight: '26px',
    textAlign: 'justify',
    color:'#4F506D',
    fontSize:' 18px',
    paddingBottom: '2px',
  },
'& .btntypo':{
    fontFamily: "Avenir Next Ultra Light",
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#4F506D',
  textTransform: 'none',
  padding:"12px 20.5px"
},
"& .closeIcon": {
  width: '35px',
  height: '35px',
  cursor: 'pointer',
},
"& .bottombtn":{
  height: "8rem",
  position: "sticky",
  bottom: "0px",
  backgroundColor: "#FFFFFF",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
},
"& .acpbtn":{
  background: "#4F506D",
  width:"80%",
  fontFamily: "Avenir Regular",
  fontSize: "20px",
  fontWeight: "400",
  lineHeight: "24px",
  textAlign: "center",
  color:"#FFFFFF",
  height: "38%",
  borderRadius: "12px"
  }
});

const DialogStyling= styled(Dialog)({
"& .MuiDialog-paperFullWidth":{
  borderRadius:"12px"
}
})