import React from "react";

// Customizable Area Start
import {
  Box,
  styled,
  Tabs,
  Tab,
  Typography,
  Input,
  Dialog,
  DialogContent,
  IconButton,
  Button,
  CircularProgress
} from "@material-ui/core";

import UserProfileBasicController, { Props } from "./UserProfileBasicController";
import { carouselImage, sleek, carousel_userprofile, edit, successResetImage } from "./assets";
import BlockIcon from '@material-ui/icons/Block';
import { Formik } from "formik";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CancelWarningPopup from "./components/CancelWarningPopup.web";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import * as Yup from "yup";
import TermsandConditionsModel from "../../termsconditions/src/components/TermsandConditionsModel.web";
import InvalidModal from "../../interactivefaqs/src/components/InvalidModal.web";

// Customizable Area End

export default class UserProfile extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   // Customizable Area Start
   companyProfile(){
    return(
        <Box className="contentContainer">
            <Box className="field">
                <Typography className="fieldTitle">COMPANY NAME</Typography>
                <Box className="fieldValue">{this.state.companyProfileData.name}<BlockIcon className="block"/></Box>
            </Box>
            <Box className="field">
                <Typography className="fieldTitle">PRIMARY CONTACT</Typography>
                <Box className="fieldValue">{this.state.companyProfileData.point_of_contact_name}<BlockIcon className="block"/></Box>
            </Box>
            <Box className="field">
                <Typography className="fieldTitle">EMAIL</Typography>
                <Typography className="fieldValue">{this.state.companyProfileData.email}<BlockIcon className="block"/></Typography>
            </Box>
            <Box className="field">
                <Typography className="fieldTitle">PHONE NUMBER</Typography>
                <Typography className="fieldValue">{this.state.companyProfileData.phone_number}<BlockIcon className="block"/></Typography>
            </Box>
            <Box className="field">
                <Typography className="fieldTitle">LOGO</Typography>
                <img className="logoImg" src={this.state.companyProfileData.logo} height={15} width={82} />
            </Box>
            <Box className="field">
                <Typography className="fieldTitle">CONTRACT START DATE</Typography>
                <Typography className="fieldValue" data-test-id="contractStartDate">{this.formatDate(this.state.companyProfileData.contract_start_date)}</Typography>
            </Box>
            <Box className="field">
                <Typography className="fieldTitle">CONTRACT END DATE</Typography>
                <Box className="fieldValue">{this.formatDate(this.state.companyProfileData.contract_end_date)}<Typography className="daysLeft">{this.state.companyProfileData.daysLeft}</Typography></Box>
            </Box>
        </Box>
    )
   }

   userProfile(){
    return(
        <Formik
        innerRef={this.formikRef}
        initialValues={{
          name: this.state.userProfileData.full_name, 
          phoneNumber: this.state.userProfileData.full_phone_number,
        }}
        data-test-id="userProfileFormik"
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting, setErrors }) => {  
          const errors:{ name?:string, phoneNumber?:string} = {};

          if (!values.name) {
            errors.name = "Name is required";
          } 

          if (!values.phoneNumber) {
            errors.phoneNumber = "Phone number is required";
          }
          else if(!Yup.string().matches(/^\+\d+$/, "Invalid mobile number").isValidSync(values.phoneNumber)){
            errors.phoneNumber = "Invalid mobile number";
          }          

          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setSubmitting(false);
            return ;
          }    

          this.updateUserProfileData();
        }}
      >
        {({ values, errors, setFieldValue, handleSubmit, setErrors }) => (
        <form className="form" onSubmit={handleSubmit}>
            <Box className="contentContainer">
                <Box className="field">
                    <Typography className="fieldTitle">NAME</Typography>
                    <InputField
                    style={{ boxShadow: errors.name ? "0px 0px 3px 0px #C5475B" : "" }}
                    data-test-id="nameField"
                    name="name"
                    fullWidth={true}
                    disableUnderline={true}
                    value={values.name}
                    onChange={(e:{target:{value:string}}) => { this.setName(e.target.value); setFieldValue("name", e.target.value); setErrors({...errors, name:""}); }}
                    />
                </Box>
                <Box className="field">
                    <Typography className="fieldTitle">EMAIL</Typography>
                    <Box className="fieldValue">{this.state.userProfileData.email}<BlockIcon className="block"/></Box>
                </Box>
                <Box className="field">
                    <Typography className="fieldTitle">PHONE NUMBER</Typography>
                    <InputField
                    style={{ boxShadow: errors.phoneNumber ? "0px 0px 3px 0px #C5475B" : "" }}
                    data-test-id="phoneNumberField"
                    name="phoneNumber"
                    type="text"
                    fullWidth={true}
                    disableUnderline={true}
                    value={values.phoneNumber}
                    onChange={(e:{target:{value:string}}) => { this.setPhoneNumber(e.target.value); setFieldValue("phoneNumber", e.target.value); setErrors({...errors, phoneNumber:""}); }}
                    />
                </Box>
                <Box className="field">
                    <Typography className="fieldTitle">PASSWORD</Typography>
                    <Box data-test-id="changePassword" className="fieldValue" style={{ paddingTop: "13px", paddingBottom: "7px" }} onClick={()=>this.setDialogName("ResetPassword")}>******** <img className="editPassword" src={edit.default}/></Box>
                </Box>
            </Box>
            <Box>
            {errors && (
                (errors.name && errors.name.includes("required")) || 
                (errors.phoneNumber && errors.phoneNumber.includes("required"))
                ) && 
                <div className="errorMessage">
                <ReportProblemOutlinedIcon/> 
                <p>
                    {errors.name && errors.name.includes("required") && (errors.phoneNumber && errors.phoneNumber.includes("required"))
                    ? "Missing fields." 
                    : "Missing field."
                    }
                </p>
                </div>}
            {errors && (errors.phoneNumber && errors.phoneNumber.includes("Invalid mobile number")) && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Invalid details.</p></div>}
            {this.state.updateProfileFailure && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>{this.state.updateProfileFailureMessage}</p></div>}
            </Box>
        </form>
        )}
    </Formik>
    )
   }
  // Customizable Area End
  render() {

    if(this.state.renderInvalidScreen)
        {
            return (<InvalidModal carouselImage={carouselImage.default} sleekImage={sleek.default} heading={"Oops!"} description={"Unable to load page information."} firstButtonTitle={"Home"} redirectToDashboard={this.redirectToDashboard} secondButtonTitle={"Support"} />)
        }

    if(this.state.isLoading){
        return(
            <Loader>
                <CircularProgress />
            </Loader>
        )
    }

    return (
       // Customizable Area Start
       <>
        <UserProfileContainer>
            <Box className="header">
                <Box></Box>
                <img src={this.state.companyProfileData.logo} height="15px" width="82px" style={{display:"none"}}/>
                <img src={sleek.default} height="20px" width="61px" style={{ opacity: "0.3" }} />
            </Box>
            <Box className="body">
                <Box className="leftContainer">
                    <Box className="tab">
                    <Tabs data-test-id="tabs" value={this.state.tabValue} onChange={this.handleTabChange} aria-label="simple tabs">
                        <Tab label="Company" />
                        <Tab label="User" />
                    </Tabs>
                    <Typography className="description">View company details or update your user info.</Typography>
                    { this.state.tabValue === 0 ? this.companyProfile() : this.userProfile() }
                    </Box>
                </Box>
                <img src={carousel_userprofile.default}/>
            </Box>
            <Box className="footerContainer">
                { this.state.isDirty && 
                    <Box className="footer">
                        <Typography data-test-id="clearDetails" onClick={this.cancelPopupHandler} >CANCEL</Typography>
                        <Typography onClick={this.formikRef.current && this.formikRef.current.submitForm} >SAVE AND EXIT</Typography>
                    </Box>
                }
            </Box>
        </UserProfileContainer>

        <ResetPasswordDialog open={ this.state.openDialogName === "ResetPassword" } onClose={ this.closeDialog }>
            <DialogContent className="container">
                <Box>
                    <Typography className="title">Change password</Typography>
                    <Formik
                    initialValues={{
                        currentPasscodeField: this.state.currentPassword,
                        passcodeInputField: this.state.password,
                        confirmPasscodeInputField: this.state.confirmPassword,
                    }}
                    data-test-id="formikPassword"
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={(values, { setSubmitting, setErrors }) => {  
                        const errors:{ currentPasscodeField?:string, passcodeInputField?:string, confirmPasscodeInputField?: string, passcodeMustMatch?: string} = {};

                        if (!values.currentPasscodeField) {
                            errors.currentPasscodeField = "Current passcode is required";
                        }
                        if (!values.passcodeInputField) {
                            errors.passcodeInputField = "Passcode is required";
                        }
                        if (!values.confirmPasscodeInputField){
                            errors.confirmPasscodeInputField = "Confirm passcode is required";
                        }
                        
                        if (this.state.password !== this.state.confirmPassword && Object.keys(errors).length === 0){
                            errors.passcodeMustMatch = "PasscodeInputField must match";
                        }                    

                        if (Object.keys(errors).length > 0) {
                        setErrors(errors);
                        setSubmitting(false);
                        return ;
                        }               
                        this.updateUserPassword();
                    }}
                    >
                    {({ values, errors, setFieldValue, handleSubmit, setErrors }) => (
                    <form className="form" onSubmit={handleSubmit}>
                        <Box className="inputContainer">
                        <Box className="inputField" style={{ paddingRight: "50px", boxShadow: (errors && errors.currentPasscodeField || this.state.resetPasswordFailure )? "0px 0px 3px 0px #C5475B" : ''}}>
                            <p className="inputTitle">Current Password</p>
                            <Input
                            data-test-id="currentPasswordField"
                            disableUnderline={true}
                            name="currentPasscodeField" fullWidth={true}
                            inputProps={{
                                style: {
                                paddingTop: this.state.enablePasswordField ? '9px' : '',
                                boxSizing:  "border-box",
                                }
                            }}
                            className="resetInput"
                            value={ this.state.enablePasswordField ? "*".repeat(this.state.currentPassword.length) : this.state.currentPassword}
                            onChange={(e: any) => {this.setCurrentPassword(e.target.value, e.nativeEvent?.data, e.target.selectionStart); setFieldValue("currentPasscodeField", e.target.value); setErrors({...errors, currentPasscodeField:""} as any); this.clearResetPasswordStatus()}}
                            />
                            <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={this.handleShowCurrentPassword}
                            data-test-id="hideCurrentPassword"
                            style={{ position: "absolute", top: 0, 
                                bottom: 0, right: "14px", 
                                cursor: "pointer"}}
                            >
                            {!this.state.enablePasswordField ? (
                                <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                            ) : (
                                <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/>
                            )}
                            </IconButton>
                            </Box>
                        <Box className="inputField" style={{ paddingRight: "50px", boxShadow: (errors && errors.passcodeInputField || (errors as any).passcodeMustMatch) || this.state.resetPasswordFailure  ? "0px 0px 3px 0px #C5475B" : ''}}>
                            <p className="inputTitle">New Password</p>
                            <Input
                            className="resetInput"
                            data-test-id="passwordField"
                            disableUnderline={true}
                            name="passcodeInputField" fullWidth={true}
                            inputProps={{
                                style: {
                                paddingTop: this.state.enableNewPasswordField ? '9px' : '',
                                boxSizing:  "border-box",
                                }
                            }}
                            value={ this.state.enableNewPasswordField ? "*".repeat(this.state.password.length) : this.state.password}
                            onChange={(e: any) => {this.setPassword(e.target.value, e.nativeEvent?.data, e.target.selectionStart); setFieldValue("passcodeInputField", e.target.value); setErrors({...errors, passcodeInputField:"", passcodeMustMatch:""} as any); this.clearResetPasswordStatus()}}
                            />
                            <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={this.handleShowPassword}
                            data-test-id="hidePassword"
                            style={{ position: "absolute", top: 0, 
                                bottom: 0, right: "14px", 
                                cursor: "pointer"}}
                            >
                            {!this.state.enableNewPasswordField ? (
                                <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                            ) : (
                                <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/>
                            )}
                            </IconButton>
                        </Box>
                        <Box className="inputField" style={{ paddingRight: "50px", boxShadow: (errors && errors.confirmPasscodeInputField || (errors as any).passcodeMustMatch) || this.state.resetPasswordFailure ? "0px 0px 3px 0px #C5475B" : ''}}>
                            <p className="inputTitle">Confirm New Password</p>
                            <Input
                            className="resetInput"
                            data-test-id="confirmPasswordField"
                            name="confirmPasscodeInputField"
                            disableUnderline={true}
                            fullWidth={true}
                            inputProps={{
                                style: {
                                paddingTop: this.state.enableReTypePasswordField ? '9px' : '',
                                boxSizing:  "border-box",
                                }
                            }}
                            value={ this.state.enableReTypePasswordField ? "*".repeat(this.state.confirmPassword.length) : this.state.confirmPassword}
                            onChange={(e: any) => {this.setConfirmPassword(e.target.value, e.nativeEvent?.data, e.target.selectionStart); setFieldValue("confirmPasscodeInputField", e.target.value); setErrors({...errors, confirmPasscodeInputField:"", passcodeMustMatch:""} as any); this.clearResetPasswordStatus()}}
                            />
                            <IconButton
                            data-test-id="hideConfirmPassword"
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={this.handleShowConfirmPassword}
                            style={{ top: 0, bottom: 0, position: "absolute", right: "14px", cursor: "pointer"}}
                            >
                            {!this.state.enableReTypePasswordField ? (
                                <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                            ) : (
                                <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/>
                            )}
                            </IconButton>
                        </Box>
                        </Box>
                        <Box style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <Box style={{width: "100%", height:"54px", display:"flex", flexDirection:"column-reverse"}}>
                            {( errors && 
                            (errors.currentPasscodeField && errors.currentPasscodeField.includes("required") || 
                            errors.passcodeInputField && errors.passcodeInputField.includes("required") || 
                            errors.confirmPasscodeInputField && errors.confirmPasscodeInputField.includes("required"))) && 
                            <div className="errorMessage"><ReportProblemOutlinedIcon/> 
                            <p>{errors && 
                                [ errors.currentPasscodeField && errors.currentPasscodeField.includes("required"),
                                errors.passcodeInputField && errors.passcodeInputField.includes("required"),
                                errors.confirmPasscodeInputField && errors.confirmPasscodeInputField.includes("required")
                                ].filter(Boolean).length >= 2  ? "Missing fields." : "Missing field."}</p></div>}
                            {errors && ((errors as any).passcodeMustMatch && (errors as any).passcodeMustMatch.includes("PasscodeInputField must match"))&& <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Passwords do not match.</p></div>}
                            {this.state.resetPasswordFailure && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>{this.state.resetPasswordFailureMessage}</p></div>}
                            </Box>
                            <Button data-test-id="btnEmailLogIn" type="submit" className="signButton">Continue</Button>
                            <Box style={{display:"flex"}}>
                            <p style={{margin: 0,
                                color: "#BBBED2",
                                fontWeight: 500, marginTop: "20px",
                                fontSize: "13px", lineHeight: "10px",
                                textAlign: "left", cursor: "pointer",
                                letterSpacing: "-0.10000000149011612px",fontFamily: "Avenir Next Medium", paddingLeft: "6px"
                                }}>By proceeding, you agree to our <span data-test-id={"termsAndCondition"} style={{
                                fontSize: "13px", fontWeight: 500,
                                textAlign: "left", color: "#4F506D", letterSpacing: "-0.25px"
                                }}
                                onClick={()=>this.setDialogName("TermsAndCondition")}
                                >Terms and Conditions.</span></p>
                            </Box>
                        </Box>
                    </form>
                    )}
                    </Formik>
                </Box>
            </DialogContent>
        </ResetPasswordDialog>

        <ResetPasswordSuccess open={ this.state.openDialogName === "SuccessfulReset" } onClose={ this.closeDialog }>
            <DialogContent className="container">
                <img src={successResetImage.default} height="200px" width="200px" />
                <Typography className="message">{this.state.updatedStatus}</Typography>
            </DialogContent>
        </ResetPasswordSuccess>

        <CancelWarningPopup   open={this.state.cancelPopupBool} title ={"Are you sure you want to leave? Any unsaved changes will be lost."} btnText= {"Leave Now"}
            onCancel={this.cancelPopup}
            onConfirm={this.confirmCancelPop}
        />

        <TermsandConditionsModel
            open={this.state.openDialogName === "TermsAndCondition"}
            onClose={this.closeTermsAndConditionDialog}
            disc={this.state.termsAndConditons}
            renderAccept={false}
        />

       </>
       // Customizable Area End
    );
  }
}
// Customizable Area Start 
const UserProfileContainer = styled(Box)({
    padding: "15px",
    paddingBottom: "0",
    boxSizing: "border-box",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    '& .header':{
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 25px",
        paddingTop: "18px",
        boxSizing: "border-box",
        paddingBottom: "3px"
    },
    '& .body':{
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between"
    },
    '& .leftContainer':{
        padding: "96px 20px 60px 45px",
        '& .description':{
            marginTop: "8px",
            fontFamily: "Avenir Light",
            fontSize: "16px",
            fontWeight: 300,
            lineHeight: "24px",
            color: "#4F506D"
        },
        '& .contentContainer':{
            marginTop: "48px",
            paddingLeft: "28px",
            gap: "28px",
            display: "flex",
            flexDirection: "column"
        },
        '& .field':{
            display: "flex",
            alignItems: "center",
            position: "relative",
            '& .fieldTitle':{
                fontSize: "16px",
                fontWeight: 600,
                lineWeight: "24px",
                letterSpacing: "0.2em",
                color: "#000000",
                width: "300px",
                fontFamily: "Avenir Next DemiBold",
            },
            '& .fieldValue':{
                fontFamily: "Avenir Light",
                display: "flex",
                width: "256px",
                fontSize: "14px",
                fontWeight: 300,
                lineHeight: "16px",
                color: "#696A92",
                padding: "10px 12px",
                borderRadius: "12px",
                boxSizing: "border-box",
                boxShadow: "-1.2px 1px 5px 0px #C9CADC, 0.2px 0px 1.8px 0px #E7E8EF",
            },
            '& .fieldValue:hover':{
                boxShadow: "-4px 1px 14px 0px #72749D66",
                cursor: "default"
            },
            '& .fieldValue:hover .block':{
                display: "flex"
            },
            '& .logoImg':{
                boxShadow: "-1.2px 1px 5px 0px #C9CADC, 0.2px 0px 1.8px 0px #E7E8EF",
                padding: "26px 24px",
                borderRadius: "12px"
            },
            '& .logoImg:hover':{
                boxShadow: "-4px 1px 14px 0px #72749D66"
            },
            '& .block':{
                width: "24px",
                height: "24px",
                color: "#DADADA",
                display: "none",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: "8px",
                marginTop: "auto",
                marginBottom: "auto",
            },
            '& .editPassword':{
                width: "16px",
                height: "16px",
                color: "#DADADA",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: "12px",
                marginTop: "auto",
                marginBottom: "auto",
                cursor: "pointer"
            },
            '& .daysLeft':{
                fontFamily: "Avenir Light",
                fontSize: "12px",
                fontWeight: 300,
                lineHeight: "18px",
                marginLeft: "auto"
            }
        }
    },
    '& .rightContainer':{
        height: "100%",
        '& img':{
            height: "100%"
        }
    },
    '& .tab':{
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        '& .MuiTabs-root':{
            minHeight: "fit-content"
        },
        '& .MuiTabs-flexContainer':{
            gap: "32px"
        },
        '& .MuiTab-root':{
            minWidth: "fit-content",
            minHeight: "fit-content",
            padding: 0,
            fontFamily: "Avenir Next Medium",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing:"0.2em",
            color: "#B3BFDB",
            opacity: 0.5
        },
        '& .MuiTab-textColorInherit.Mui-selected':{
            opacity: 1
        }
    },
    '& .errorMessage':{
        marginLeft: "325px",
        marginTop: "60px",
        fontSize: "12px",
        fontWeight: 400,
        textAlign: "left",
        color: "#C5475B",
        display: "flex",
        fontFamily: "Avenir Next Medium",
        marginBottom: "16px",
        '& p':{
            margin: 0,
            marginLeft: "8px",
            height: "14px",
        },
        '& .MuiSvgIcon-root':{
            height: "16px",
            width: "18px" 
        }
    },
    '& .footerContainer':{
        paddingBottom: "15px",
        paddingTop: "4px",
        minHeight: "53px"
    },
    '& .footer':{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        '& p':{
            marginLeft: "auto",
            fontFamily: "Avenir Next DemiBold",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0.2em",
            color: "#B3BFDB",
            paddingRight: "30px",
            cursor: "pointer"
        }
    },
})

const InputField = styled(Input)({
    fontFamily: "Avenir Light",
    display: "flex",
    width: "256px",
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "16px",
    color: "#696A92",
    padding: "10px 12px",
    borderRadius: "12px",
    boxSizing: "border-box",
    height: "36px",
    boxShadow: "-1.2px 1px 5px 0px #C9CADC, 0.2px 0px 1.8px 0px #E7E8EF",
    '& .MuiInputBase-input':{
        padding: 0
    },
    '&.Mui-focused, &:hover': {
        boxShadow: "-4px 1px 14px 0px #72749D66"
    }
})

const ResetPasswordDialog = styled(Dialog)({
    '& ::-webkit-scrollbar': {
        width: "8px",
        borderRadius: "40px"
        },
    "& ::-webkit-scrollbar-track": {
        backgroundColor: "#F0F2F8",
        borderRadius: "40px"
    },
    "& ::-webkit-scrollbar-thumb":{
        width: "6px",
        backgroundColor: "#B3BFDB",
        borderRadius: "40px",
    },
    '& .MuiBackdrop-root':{
        backgroundColor: "transparent",
    },
    '& .MuiDialog-container':{
        backdropFilter: "Blur(6px)",
    },
    '& .MuiDialog-paper':{
        background: "#FAFAFA",
        boxShadow: "0px 40px 60px 0px #0000001F, 0px -4px 24px 0px #0000000D",
        borderRadius: "30px",
    },
    '& .container':{
        width: "438px",
        boxSizing: "border-box",
        padding: "48px 0px 52px 0px",
        height: "563px",
        '& .title':{
            fontFamily: "Avenir Next Regular",
            fontSize: "36px",
            fontWeight: 400,
            lineHeight: "49.18px",
            color: "#4F506D"
        },
        '& >div':{
            padding: "0 40px",
            height: "100%",
            overflow: "auto"
        }
    },
    '& .inputContainer':{
        marginTop: "56px",
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    },
    '& .inputField':{
        padding: "6px 16px",
        position: "relative",
        borderRadius: "20px",
        boxShadow: "0px 4px 9px 0px #0000000F, 0px -1px 14px 0px #00000005",
        backgroundColor: "#FFFFFF",
      '& .MuiSvgIcon-root':{
        height: "18px",
        width: "18px"
      },
      '& .MuiIconButton-root':{
        marginTop: "auto",
        marginBottom: "auto",
        height: "fit-content",
      }
    },
    '& .inputTitle':{
      fontSize: "12px",
      lineHeight: "22px",
      fontWeight: 300,
      color: "#ADB1C8",
      margin: 0,
      textAlign: "left",
      fontFamily: "Avenir Light"
    },
    '& .resetInput':{
        width: "100%",
        padding: "0",
        margin: "0",
        borderRadius: "20px",
        border: "none",
        backgroundColor: "#FFFFFF",
        fontFamily: "Avenir Light",
        fontSize: "16px",
        color: "#4F506D",
        '& .MuiInputBase-input':{
            padding: 0,
            marginBottom: "1px"
        }
    },
    '& .signButton':{ 
      padding: "16px",
      width: "100%", 
      borderRadius: "16px", 
      textTransform: "none", 
      color: "#FFFFFF",
      fontSize: "18px",
      fontWeight: 350,
      textAlign: "center",
      backgroundColor: "#4F506D",
      lineHeight: "24px",
      fontFamily: "Avenir Next Regular",
      cursor: "pointer",
      '& .MuiButton-root':{
        fontFamily: "inherit",
      }
    },
    '& .rotated-icon': {
      transform: "rotateY(180deg)",
    },
    '& .errorMessage':{
        fontSize: "12px",
        fontWeight: 400,
        textAlign: "left",
        color: "#C5475B",
        display: "flex",
        fontFamily: "Avenir Next Medium",
        marginBottom: "16px",
        '& p':{
            margin: 0,
            marginLeft: "8px",
            height: "14px",
        },
        '& .MuiSvgIcon-root':{
            height: "16px",
            width: "18px" 
        }
    },
})

const ResetPasswordSuccess = styled(Dialog)({
    '& .MuiBackdrop-root':{
        backgroundColor: "transparent",
    },
    '& .MuiDialog-container':{
        backdropFilter: "Blur(6px)",
    },
    '& .MuiDialog-paper':{
        background: "transparent",
        boxShadow: "none",
        overflow: "hidden",
    },
    '& .MuiDialogContent-root':{
        padding: 0,
        overflow: "hidden"
    },
    '& .container':{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px"
    },
    '& .message':{
        fontFamily: "Avenir Book",
        fontSize: "18px",
        fontWeight: 350,
        lineHeight: "24px",
        letterSpacing: "0.2em",
        color: "#696A92",
        textTransform: "uppercase"
    }
})

const Loader = styled('div')({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& .MuiCircularProgress-colorPrimary':{
      color: "#4F506D",
    }
})
// Customizable Area End