import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  styled,
  IconButton,
  CircularProgress
} from "@material-ui/core";

import ForgotPasswordController, {Props,configJSON} from "./ForgotPasswordController";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { sleek } from "./assets";
import * as Yup from "yup";
import { Formik } from "formik";
import CarosalModel from "../../../blocks/carouseldisplay/src/components/CarosalModel.web";
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   // Customizable Area Start
    async componentDidMount() {
      this.getNotifications();
      this.checkUrlForResetPassword()
    }

    renderEmailFormik = () =>{
      return(
        <Formik
          initialValues={{
            email: this.state.email,
          }}
          data-test-id="formikEmail"
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting, setErrors }) => {  
            const errors:{ email?:string} = {};

            if (!values.email) {
              errors.email = configJSON.emailRequired;
            } 
            else if (!Yup.string().email().isValidSync(values.email)) {
              errors.email = configJSON.invalidEmail;
            }

            if (Object.keys(errors).length > 0) {
              setErrors(errors);
              setSubmitting(false);
              return ;
            }    

            this.emailVerificationHandler();
          }}
        >
          {({ values, errors, submitCount, setFieldValue, handleSubmit, setErrors }) => (
          <form className="form" onSubmit={handleSubmit}>
            <Box>
              <Box className="inputField" style={{boxShadow: errors && errors.email && submitCount > 0 || this.state.emailVerificationFailed ? "0px 0px 3px 0px #C5475B" : ''}}>
                <p className="inputTitle">Email</p>
                <InputField
                data-test-id="emailField"
                name="email"
                fullWidth={true}
                disableUnderline={true}
                value={values.email}
                onChange={(e:{target:{value:string}}) => {this.setEmail(e.target.value); setFieldValue("email", e.target.value); setErrors({...errors, email:""}); this.clearForgotStatus()}}
                />
              </Box>
            </Box>
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
              <Box style={{width: "100%", display:"flex", flexDirection:"column-reverse"}}>
              {( errors && errors.email && errors.email.includes("required") && submitCount > 0) && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Missing field.</p></div>}
              {errors && errors.email && errors.email.includes("Invalid email format") && submitCount > 0 && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Invalid credentials.</p></div>}
              {this.state.emailVerificationFailed && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>{this.state.emailVerificationMessage}</p></div>}
              </Box>
              <Button data-test-id="btnSendLink" type="submit" className="signButton">Send link</Button>
              <Box style={{height: "min(80px, 10vh)"}}>
                { this.state.linkSent && <>
                  <p className="sentText">A password reset link has been sent to your email.</p>
                  <p className="resend" data-test-id="resendLink" onClick={this.emailVerificationHandler}>Link not received? <span>Resend</span></p>
                </>}
              </Box>
            </Box>
          </form>
          )}
        </Formik>
      )
    }

    renderResetFormik = () => {
      return(
        <Formik
          initialValues={{
            passcodeInputField: this.state.password,
            confirmPasscodeInputField: this.state.confirmPassword,
          }}
          data-test-id="formikPassword"
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting, setErrors }) => {  
            const errors:{ passcodeInputField?:string, confirmPasscodeInputField?: string, passcodeMustMatch?: string} = {};

            if (!values.passcodeInputField) {
              errors.passcodeInputField = configJSON.passcodeRequired;
            }
            if (!values.confirmPasscodeInputField){
              errors.confirmPasscodeInputField = configJSON.confirmPasswordRequired;
            }
            
            if (this.state.password !== this.state.confirmPassword && Object.keys(errors).length === 0){
              errors.passcodeMustMatch = configJSON.passcodeMustMatch;
            }

            if (Object.keys(errors).length > 0) {
              setErrors(errors);
              setSubmitting(false);
              return ;
            }               
            this.resetPasswordHandler();
          }}
        >
          {({ values, errors, submitCount, setFieldValue, handleSubmit, setErrors }) => (
          <form className="form" onSubmit={handleSubmit}>
            <Box>
              <Box className="inputField" style={{ paddingRight: "50px", boxShadow: errors && errors.passcodeInputField && submitCount > 0 || (errors as any).passcodeMustMatch ? "0px 0px 3px 0px #C5475B" : ''}}>
                <p className="inputTitle">New password</p>
                <InputField
                  data-test-id="passwordField"
                  disableUnderline={true}
                  name="passcodeInputField" fullWidth={true}
                  inputProps={{
                    style: {
                      paddingTop: this.state.enablePasswordField ? '9.5px' : '',
                      boxSizing:  "border-box",
                    }
                  }}
                  value={ this.state.enablePasswordField ? "*".repeat(this.state.password.length) : this.state.password}
                  onChange={(e: any) => {this.setPassword(e.target.value, e.nativeEvent?.data, e.target.selectionStart); setFieldValue("passcodeInputField", e.target.value); setErrors({...errors, passcodeInputField:"", passcodeMustMatch:""} as any); this.clearForgotStatus()}}
                />
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={this.handleClickShowPassword}
                  data-test-id="hidePassword"
                  style={{ position: "absolute", top: 0, 
                    bottom: 0, right: "14px", 
                    cursor: "pointer"}}
                >
                {!this.state.enablePasswordField ? (
                    <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                  ) : (
                    <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/>
                  )}
                </IconButton>
                </Box>
              <Box className="inputField" style={{ paddingRight: "50px", boxShadow: errors && errors.confirmPasscodeInputField && submitCount > 0 || (errors as any).passcodeMustMatch ? "0px 0px 3px 0px #C5475B" : '', marginTop: "min(20px, 1.2vw)", }}>
                <p className="inputTitle">Confirm password</p>
                <InputField
                  data-test-id="confirmPasswordField"
                  name="confirmPasscodeInputField"
                  disableUnderline={true}
                  fullWidth={true}
                  inputProps={{
                    style: {
                      paddingTop: this.state.enableConfirmPasswordField ? '9.5px' : '',
                      boxSizing:  "border-box",
                    }
                  }}
                  value={ this.state.enableConfirmPasswordField ? "*".repeat(this.state.confirmPassword.length) : this.state.confirmPassword}
                  onChange={(e: any) => {this.setConfirmPassword(e.target.value, e.nativeEvent?.data, e.target.selectionStart); setFieldValue("confirmPasscodeInputField", e.target.value); setErrors({...errors, confirmPasscodeInputField:"", passcodeMustMatch:""} as any); this.clearForgotStatus();}}
                />
                <IconButton
                  data-test-id="hideConfirmPassword"
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={this.handleClickShowConfirmPassword}
                  style={{ top: 0, bottom: 0, position: "absolute", right: "14px", cursor: "pointer"}}
                >
                  {!this.state.enableConfirmPasswordField ? (
                    <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                  ) : (
                    <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/>
                  )}
                </IconButton>
              </Box>
            </Box>
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
            <Box style={{width: "100%", display:"flex", flexDirection:"column-reverse"}}>
              {( errors && 
                (errors.passcodeInputField && errors.passcodeInputField.includes("required") || 
                errors.confirmPasscodeInputField && errors.confirmPasscodeInputField.includes("required"))) && submitCount > 0 && 
                <div className="errorMessage"><ReportProblemOutlinedIcon/> 
                <p>{errors && 
                  [errors.passcodeInputField && errors.passcodeInputField.includes("required"),
                    errors.confirmPasscodeInputField && errors.confirmPasscodeInputField.includes("required")
                  ].filter(Boolean).length >= 2  ? "Missing fields." : "Missing field."}</p></div>}
              {errors && ((errors as any).passcodeMustMatch && (errors as any).passcodeMustMatch.includes("passcodeInputField must match")) && submitCount > 0 && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Passwords do not match.</p></div>}
              {this.state.emailVerificationFailed && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>{this.state.emailVerificationMessage}</p></div>}
              </Box>
              <Button data-test-id="btnEmailLogIn" type="submit" className="signButton">Submit</Button>
              <Box style={{ display:"flex", height: "min(80px, 10vh)"}}>
                <p className="resend" data-test-id="redirectToSignIn" style={{ marginTop: "auto"}} onClick={this.redirectToSignIn}>Return to <span>Sign in</span></p>
            </Box>
            </Box>
          </form>
          )}
        </Formik>
      )
    }
  // Customizable Area End
  render() {

    if(this.state.isLoading){
      return(
        <Loader>
          <CircularProgress />
        </Loader>
      )
    }

    return (
       // Customizable Area Start
      <ForgotPasswordContainer>
        <Box className="leftContainer">
        <CarosalModel data={this.state.carosalData} />
        </Box>
        <Box className="rightContainer">
          <img src={sleek.default} style={{ width: "min(81px, 5.5vw)", height: "min(27px, 1.7vw)", marginLeft: "auto", position: "absolute", top: "26px", right: "30px"}}/>
        <Box className="outerFormContainer">
          <Box className="formContainer">
            <p className="formTitle">Forgot password</p>
            { !this.state.enableResetPassword ?
              this.renderEmailFormik()
            :
              this.renderResetFormik()
            }
          </Box>
        </Box>
        </Box>
      </ForgotPasswordContainer>
       // Customizable Area End
    );
  }
}
// Customizable Area Start 

const ForgotPasswordContainer = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexGrow: 1,
    height: "100%",
    '& .leftContainer':{
      flexGrow: 1,
      height: "100%",
      '& div, img':{
        height: "100% !important",
        padding: "0 !important",
        maxWidth: "100% !important"
      }
    },
    '& .rightContainer':{
      maxWidth: "538px",
      width: "38%",
      display: "flex",
      flexDirection: "column",
      padding: "26px",
      height: "100%",
      boxSizing: "border-box",
      justifyContent: "center"
    },
    '& .sleekText':{
      margin: "0",
      textAlign: "end",
    },
    '& .outerFormContainer':{
      paddingLeft: "18px",
      paddingRight: "50px",
      width: "100%",
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center"
    },
    '& .formContainer':{
      width: "100%",
      display: "flex",
      height: "calc(100% - min(143px, 7vw))",
      borderRadius: "30px",
      flexDirection:"column",
      boxSizing: "border-box",
      padding: "min(30px, 3vh) min(40px, 5vh) min(25px, 2.8vh)",
      backgroundColor: "#ffffff",
      boxShadow: "-2px 10px 19px 0px #D5D5DC, 2px 0px 16px 0px #EEEEF2",
    },
    '& .form':{
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "0"
    },
    '& .formTitle':{
      fontSize: "min(36px, 2.5vw)",
      fontWeight: 275,
      lineHeight: "min(49.18px, 3.5vw)",
      textAlign: "left",
      color: "#4F506D",
      marginTop: "min(31px, 2.8vh)",
      marginBottom: "min(57px, 5vh)",
      fontFamily: "Avenir Next Ultra Light"
    },
    '& .inputField':{
        padding: "6px 16px",
        position: "relative",
        borderRadius: "20px",
        boxShadow: "0px 1px 3px 0px #72749D4F",
      '& .MuiSvgIcon-root':{
        height: "18px",
        width: "18px"
      },
      '& .MuiIconButton-root':{
        marginTop: "auto",
        marginBottom: "auto",
        height: "fit-content",
      }
    },
    '& .inputTitle':{
      fontSize: "min(12px, 0.9vw)",
      lineHeight: "min(22px, 1.3vw)",
      fontWeight: 300,
      color: "#ADB1C8",
      margin: 0,
      textAlign: "left",
      fontFamily: "Avenir Light"
    },
    '& .signButton':{ 
      padding: "min(16px, 2vh)",
      width: "100%", 
      borderRadius: "12px", 
      textTransform: "none", 
      color: "#FFFFFF",
      fontSize: "min(18px, 1.2vw)",
      fontWeight: 350,
      textAlign: "center",
      backgroundColor: "#4F506D",
      lineHeight: "24px",
      fontFamily: "Avenir Next Regular",
      cursor: "pointer",
      '& .MuiButton-root':{
        fontFamily: "inherit",
      }
    },
    '& .rotated-icon': {
      transform: "rotateY(180deg)",
    },
    '& .errorMessage':{
      display: "flex",
      fontSize: "min(12px, 0.9vw)",
      textAlign: "left",
      color: "#C5475B",
      fontWeight: 400,
      marginRight: "auto",
      marginBottom: "min(16px, 1.7vh)",
      fontFamily: "Avenir Next Regular",
      '& p':{
        marginLeft: "5px",
        height: "14px",
        margin: 0,
      },
      '& .MuiSvgIcon-root':{
        height: "14px",
        width: "16px"
      }
    },
    '& .resend':{
      margin: "0",
      fontWeight: 300,
      fontSize: "min(16px, 1.1vw)",
      letterSpacing: "0.10000000149011612px",
      lineHeight: "min(19px, 1.4vw)",
      cursor: "pointer",
      textAlign: "center",
      color: "#595959",
      fontFamily: "Avenir Light",
      '& span':{
        fontWeight: 500,
        color: "#000000",
        fontFamily: "Avenir Next Medium",
      }
    },
    '& .sentText':{
      fontSize: "min(13px, 0.9vw)",
      fontWeight: 500,
      lineHeight: "9px",
      letterSpacing: "-0.10000000149011612px",
      textAlign: "center",
      color: "#BABDD4",
      marginTop: "min(20px,2.6vh)",
      marginBottom: "min(32px, 2.7vh)",
      fontFamily: "Avenir Next Medium",
    }
})
  
  const InputField = styled(Input)({
    width: "100%",
    padding: "0",
    margin: "0",
    borderRadius: "20px",
    border: "none",
    backgroundColor: "#FFFFFF",
    fontFamily: "Avenir Light",
    fontSize: "min(16px, 1.1vw)",
    color: "#4F506D",
    '& .MuiInputBase-input':{
      padding: 0,
      marginBottom: "1px"
    }
  })

  const Loader = styled('div')({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& .MuiCircularProgress-colorPrimary':{
      color: "#4F506D",
    }
  })

// Customizable Area End