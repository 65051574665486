import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React, { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  // Customizable Area Start
  token: string;
  faq: any;
  isVisible: boolean;
  activeId: number;
  activeTitle: string;
  activeContent: string;
  activeCreatedAt: string;
  activeUpdatedAt: string;
  title: string;
  content: string;
  value: any;
  isExpanded: string | boolean;
  renderInvalidScreen: boolean;
  faqType: string;
  invalidScreen: { heading: string, description: string },
  faqTypes: string[],
  openDialogName: string;
  isVideoPlaying: boolean;
  video: { currentTime: number, duration: number, isDragging: boolean, isPlaying: boolean, muted: boolean }
  selectedVideoIndex: number;
  selectedImage: string;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class InteractivefaqsSharedController extends BlockComponent<
  Props,
  S,
  SS
> {
  value: any;
  editorState: any;
  faqApiCallId: any;
  deleteFaqApiCallId: any;
  addFaqApiCallId: any;
  richtext: any;
  editor: any;
  onChange: (editorState: any) => void;
  setEditor: (editor: any) => void;
  focusEditor: () => void;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.editorState = null; //createEditorStateWithText("");

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      faq: [],
      isVisible: false,
      activeId: 0,
      activeTitle: "",
      activeContent: "",
      activeCreatedAt: "",
      activeUpdatedAt: "",
      title: "",
      content: "",
      value: this.value,
      isExpanded: false,
      renderInvalidScreen: false,
      faqType: "",
      invalidScreen: { heading: "", description: "" },
      faqTypes: [],
      openDialogName: "",
      isVideoPlaying: false,
      video: { currentTime: 0, duration: 0, isDragging: false, isPlaying: false, muted: false },
      selectedVideoIndex: 0,
      selectedImage: "",
      isLoading: true
    };
    this.onChange = (value) => {
      this.value = value;
      this.setState({ value: value });
    };
    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
    this.contentRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getFaq(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.faqApiCallId) {
          // alert(JSON.stringify(responseJson.data.faqs))
          this.handleFaqApiResponse(responseJson);
        }
        if (apiRequestCallId === this.addFaqApiCallId) {
          this.props.navigation.goBack();
        }
      }
      else if (responseJson && (responseJson.error || responseJson.errors) && apiRequestCallId === this.faqApiCallId){
        this.setState({ renderInvalidScreen: true, invalidScreen: { heading: "Oops!", description: "Unable to load page information." }, isLoading: false})
      }
      else if (
        responseJson &&
        !responseJson.data &&
        apiRequestCallId === this.deleteFaqApiCallId
      ) {
        this.setState({ isVisible: false });
        // alert(JSON.stringify(responseJson.data.faqs))
        this.getFaq(this.state.token);
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  contentRef:any;

  async componentWillUnmount() {
    const images = this.contentRef.current && this.contentRef.current.querySelectorAll('img');
    if(images){
      images.forEach((img:any) => {
        img.removeEventListener('click', this.handleImageClick);
      });
    }
  }

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeTitle: item.title,
      activeContent: item.content,
      activeCreatedAt: item.created_at,
      activeUpdatedAt: item.updated_at,
      isVisible: !this.state.isVisible,
    });
  };
  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  addFaq() {
    //@ts-ignore
    this.props.navigation.navigate("AddInteractivefaqs");
  }

  setTitle = (txt: string) => {
    this.setState({ title: txt });
  };

  deleteFAQ(id: number) {
    this.deleteFaqApiCall(configJSON.faqApiEndPoint + `/${id}`);
  }

  addFaqCall = () => {
    if (this.state.title.trim() === "") {
      this.showAlert(configJSON.configError, configJSON.configErrorTitle);
      return false;
    } else if (this.state.content === "") {
      this.showAlert(configJSON.configError, configJSON.configErrorContent);
      return false;
    } else {
      var data = {
        title: this.state.title,
        content: this.state.content,
      };

      const header = {
        "Content-Type": configJSON.faqApiApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addFaqApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.faqApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteFaqApiCall = (endPointURL: string) => {
    const header = {
      "Content-Type": configJSON.faqApiApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFaqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFaq = async (token: string) => {    
    const header = {
      "Content-Type": configJSON.faqApiApiContentType,
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.faqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAccordionChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean | string) => {
    isExpanded = isExpanded ? panel : false;    
    this.setState({isExpanded}, () => this.imageHandler());
  };


  handleFaqApiResponse = (faqResponse: { data: { id: string; type: string; attributes: { id: number; title: string; content: string; sequence_number: null | number; faq_type: string; images: any[]; video: null | string; } }[] }) => {
    const groupedFaqs = faqResponse.data.reduce((acc: Record<string, { id: number; title: string; content: string; sequence_number: null | number; faq_type: string; images: any[]; video: null | string }[]>, faq: { id: string; type: string; attributes: { id: number; title: string; content: string; sequence_number: null | number; faq_type: string; images: any[]; video: null | string } }) => {
      const { faq_type } = faq.attributes;
      if (!acc[faq_type]) {
        acc[faq_type] = [];
      }
      acc[faq_type].push(faq.attributes);
      return acc;
    }, {});

    const uniqueFaqTypes = Object.keys(groupedFaqs);

    this.setState({ faq: groupedFaqs, faqTypes: uniqueFaqTypes, faqType: uniqueFaqTypes[0], isLoading: false });
  };

  redirectToDashboard = () =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),"Dashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  setFaqType = (type: string) =>{
    this.setState({ faqType: type })    
    if(!this.state.faq[type]){
      this.setState({ renderInvalidScreen: true, invalidScreen: { heading: "Oops!", description: "Unable to load page information." }})
    }
  }

  playAccordionVideo = (index:number) =>{
    this.setState(previousState => ({ isVideoPlaying: true, selectedVideoIndex: index,  video: { ...previousState.video, currentTime:0, duration:0, isPlaying: true, muted: false }}), ()=> this.loadVideoMetaData());    
  }

  loadVideoMetaData = () => {
    const video = document.getElementById(`video-id-${this.state.selectedVideoIndex}`) as HTMLVideoElement;
    
    if (video) {
      video.play();
      this.setState(previousState => ({ video: {...previousState.video, duration: video.duration }}));
      video.addEventListener('timeupdate', this.handleTimeUpdate);
    }
  }

  handleTimeUpdate = () => {
    const video = document.getElementById(`video-id-${this.state.selectedVideoIndex}`) as HTMLVideoElement;
    const { isDragging } = this.state.video;

    if (video && !isDragging) {
      this.setState(previousState => ({ video: {...previousState.video, currentTime: video.currentTime }}));
    }
    if(video && this.state.video.duration === this.state.video.currentTime){
      this.setState(previousState => ({ video: {...previousState.video, isPlaying: false }}));
    }
  };
  
  handlePlayPause = () =>{
    const video = document.getElementById(`video-id-${this.state.selectedVideoIndex}`) as HTMLVideoElement;
    if(video){
      if(!video.paused){
        video.pause() 
        this.setState(previousState => ({ video: { ...previousState.video, isPlaying: false }}))
      }
       else{
         video.play();
         this.setState(previousState => ({ video: { ...previousState.video, isPlaying: true }}))
       }
    }
  }

  handleSliderChange = (e: {target :{ value:string }}) => {
    const video = document.getElementById(`video-id-${this.state.selectedVideoIndex}`) as HTMLVideoElement;
    const newTime = Number(e.target.value);

    if (video) {
      this.setState(previousState => ({ video:{...previousState.video, currentTime: newTime }}));
      video.currentTime = newTime;
    }
  };

  handleMouseDown = () => {
    this.setState(previousState => ({ video:{...previousState.video, isDragging: true }}));
  };

  handleMouseUp = (e:any) => {
    this.setState(previousState => ({ video:{...previousState.video, isDragging: false }}));
    this.handleSliderChange(e);
  };

  handleVideoMute = () => {
    this.setState( previousState => ({ video: {...previousState.video, muted: !previousState.video.muted}}))
  }

  handlePopupClose = () =>{
    const video = document.getElementById(`video-id-${this.state.selectedVideoIndex}`) as HTMLVideoElement;
    if (video) {
      video.removeEventListener('timeupdate', this.handleTimeUpdate);
      video.pause();
      video.currentTime = 0;
    }
    this.setState(previouState => ({ isVideoPlaying: false, video: {...previouState.video, currentTime:0, duration:0, isPlaying: false, muted: false}}))
  }

  imageHandler = () => {        
    const images = this.contentRef.current && this.contentRef.current.querySelectorAll('img');
    if(images){
      images.forEach((img:any) => {
        img.addEventListener('click', this.handleImageClick);
      });
    }
  } 

  handleImageClick = (event: { target: { src: string} }) => {
    this.setState({ selectedImage: event.target.src })
  }

  setDialogName = (name: string) =>{
    this.setState({ openDialogName: name })
  }

  closeDialig = () => {
    this.setState({ openDialogName: ""})
  }

  closeImageDialog = () =>{
    this.setState({ selectedImage: ""});
  }

  goToScreen = (screenName:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
  };
  
  // Customizable Area End
}
