import React from "react";

// Customizable Area Start
import {
  Box,
  styled,
} from "@material-ui/core";
import ForgotPasswordController, {Props} from "./ForgotPasswordController";
import { oops_invalid_link, sleek } from "./assets";

// Customizable Area End

export default class InvalidLink extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
      this.checkStorage()
    // Customizable Area End
  }

   // Customizable Area Start
  // Customizable Area End
  render() {
    return (
       // Customizable Area Start
     <>
      <InvalidContainer>
        <Box className="leftContainer">
          <img src={oops_invalid_link.default} style={{height: "100%", width: "100%"}}/>
        </Box>
        <Box className="rightContainer">
          <img src={sleek.default} style={{ width: "min(81px, 5.5vw)", height: "min(27px, 1.7vw)", marginLeft: "auto", position: "absolute", top: "26px", right: "30px"}}/>
          <Box className="oopsContainer">
            <p className="heading">Oops!</p>
            <p className="invalidText">{this.state.invalidText}</p>
            <Box className="buttonContainer">
              <button className="button" data-test-id="resetButton" onClick={this.handleInvalidButtonClick}>{this.state.invalidButton}</button>
              <button className="button" style={{backgroundColor: "#FFFFFF", color:"#4F506D"}}>Support</button>
            </Box>
            <p className="alreadyRegistered" data-test-id="alreadyRegistered" onClick={this.redirectToSignIn}>Already registered? <span>Sign in</span></p>
          </Box>
        </Box>
      </InvalidContainer>
     </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start 

const InvalidContainer = styled(Box)({
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "space-between",
  fontFamily: 'sans-serif !important',
  boxSizing: "border-box",
    '& .leftContainer':{
      flexGrow: 1,
      height: "100%",
    },
  '& .rightContainer':{
    display: "flex",
    flexDirection: "column",
    padding: "26px",
    boxSizing: "border-box",
    justifyContent: "center",
    maxWidth: "495px",
    width: "36%"
  },
  '& .oopsContainer':{
    maxWidth: "338px",
    display: "flex",
    flexDirection: "column",
    width: "75%",
    marginLeft: "6px",
    position: "relative",
    flexGrow: 0.6,
    marginTop: "auto",
    marginBottom: "min(100px, 10vh)"
  },
  '& .heading':{
    fontWeight: "300",
    textAlign: "left",
    color: "#4F506D",
    margin: 0,
    lineHeight: "min(71.05px, 4vw)",
    fontSize: "min(52px, 3vw)",
    fontFamily: "Avenir Light"
  },
  '& .invalidText':{
    textAlign: "left",
    margin: 0,
    marginTop: "min(10px, 0.6vw)",
    color: "#4F506D",
    lineHeight:"13px", 
    fontSize: "min(18px, 1.2vw)",
    fontWeight: "350",
    fontFamily: "Avenir Book"
  },
  '& .buttonContainer':{
    display: "flex", 
    flexDirection:"column", 
    marginTop:"min(50px,2.2vw)", 
    gap: "min(26px, 1.6vw)"
  },
  '& .button':{
    width: "100%",
    padding: "min(16px, 2vh)",
    backgroundColor: "#4F506D",
    border: "1px solid #4F506D",
    fontWeight: "300",
    lineHeight: "21px",
    cursor: "pointer",
    borderRadius: "12px", 
    fontSize: "min(18px, 1.2vw)",
    color: "#FFFFFF",
    height: "56px",
    fontFamily: "Avenir Light"
  },
  '& .alreadyRegistered':{
    fontWeight: "300",
    textAlign: "center",
    color: "#595959",
    marginTop: "min(178px, 18vh)",
    fontSize: "min(16px, 1.1vw)",
    lineHeight: "22px", 
    letterSpacing: "0.10000000149011612px", 
    cursor: "pointer",
    marginBottom: "0",
    fontFamily: "Avenir Light",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    '& span':{
      color: "#000000",
      fontFamily: "Avenir Next Medium"
    }
  }
})

// Customizable Area End